<template>
  <div class="site-body">
    <section class="presale-fixed-section">
      <p>
        <span>You purchased </span>
        <strong>{{ localeValue(purchasedAmount) }} {{ prjConst('tokenName') }}</strong>
        <button class="btn btn-secondary" @click="scroll('buy-token', 180)">BUY</button>
      </p>
    </section>
    <section class="presale-section presale-section-1">
      <div class="container container-max-lg">
        <div class="cont-row">
          <div class="text-cont">
            <h1>{{ prjConst('tokenName') }} Public Sale</h1>
            <h4 v-if="endOfSale"><span class="mr-3">Closed</span></h4>
            <h4 v-else-if="!publicsaleLive"><span>Starts after</span></h4>
            <div class="count-down-wrap" v-if="publicsaleLive">
              <!-- <div>
                <p class="num">LIVE</p>
              </div> -->
            </div>

            <h4 class="mt-4">
              <span class="mt-3">Delayed Token will be minted within 28 days after purchase</span>
            </h4>
            <h6 class="mt-4">
              <span class="mt-3 btn btn-outline-primary" @click="addToken('POCT')">Add POCT Token to MetaMask</span>
              <span class="ml-3 mt-3 btn btn-outline-primary" @click="addToken('BTCTH')">Add BTCTH Token to MetaMask</span>
            </h6>
            <h6 class="mt-4">
              <span class="mt-3 btn btn-outline-primary" @click="addToken('USDT')">Add USDT Token to MetaMask</span>
              <span class="mt-3 ml-4 btn btn-outline-primary" @click="addToken('BTCB')">Add BTC Token to MetaMask</span>
            </h6>
          </div>
          <div class="img-cont">
            <img src="img/img-billy-rocket.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="presale-section presale-section-2">
      <div class="container container-max-lg">
        <div class="balance-box" v-if="!endOfSale" id="buy-token">
          <div class="img-cont">
            <img src="img/img-billy-safe.png" />
          </div>
          <div class="input-cont">
            <div class="row align-items-center">
              <div class="col-12 mb-2">
                <p class="text-gray">Token Type</p>
              </div>
              <div class="col-6">
                <button class="btn btn-block mt-0 mb-4" @click="selectProduct('1')"
                  :class="{ 'btn-primary' : productId === '1', 'btn-outline-primary' : productId != '1'}" >Instant</button>
              </div>
              <div class="col-6">
                <button class="btn btn-block mt-0 mb-4" @click="selectProduct('2')"
                :class="{ 'btn-info' : productId === '2', 'btn-outline-info' : productId != '2'}">Delayed</button>
              </div>
            </div>
            <div class="row align-items-center mb-sm-5 mb-3 padding-1">
              <div class="col">
                <div class="row mb-4 mb-sm-0">
                  <div class="col col-sm-12">
                    <p class="text-gray">Your Balance</p>
                  </div>
                  <div class="col-auto"><h5>{{ localeValue(tetherBalance) }} USDT</h5></div>
                </div>
              </div>
              <div class="col-12 col-sm-auto" v-if="!endOfSale">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <button class="btn btn-secondary" @click="setTetherAmount(50)">50%</button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-secondary" @click="setTetherAmount(100)">All</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!endOfSale">
              <div class="my-2">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control text-right text-700 form-control-lg"
                    placeholder="0.0"
                    v-model="tetherAmount"
                    :max="maxTetherAmount"
                    @focus="focusAt = 'coin'"
                    @blur="adjustTetherAmount()"
                  />
                  <div class="input-group-text text-700">USDT</div>
                </div>
              </div>
              <p class="h5 text-center text-dark py-1 py-sm-3">RATIO : <strong>1</strong> {{ prjConst('tokenName') }} = <strong>{{ tokenPrice }}</strong> USDT</p>
              <div class="my-2">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control text-right text-700 form-control-lg"
                    placeholder="0.0"
                    v-model="tokenAmount"
                    @focus="focusAt = 'token'"
                    :aria-label="prjConst('tokenName')"
                  />
                  <div class="input-group-text text-700">{{ prjConst('tokenName') }}</div>
                </div>
                <p v-if="productId == '1'" class="h5 text-center text-dark py-1 py-sm-3">STOCK : <strong>{{ maxTokenAmount}}</strong> {{ prjConst('tokenName') }}</p>
                <p v-if="productId != '1'" class="h5 text-center text-dark py-1 py-sm-3">STOCK : <strong>Unlimited</strong></p>
              </div>
              <div class="row mt-0">
                <div class="col" v-if="! permitted">
                  <button class="btn btn-info btn-block btn-lg btn-shadow" @click="permitUsdt()" :disabled="tetherBalance == 0">
                    Permit
                  </button>
                </div>
                <div class="col">
                  <button class="btn btn-primary btn-block btn-lg btn-shadow" @click="buyToken()" :disabled="tetherBalance == 0 || !permitted">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<style scoped>
.presale-section-2 .balance-box .input-cont .btn.btn-primary {
    position: relative;
}
.presale-section-2 .balance-box .input-cont .btn {
    margin-top: 24px;
}
.presale-section {
  padding-top: 1px;
  padding-bottom: 40px;
}
.presale-section-2 {
  margin-top: 0px;
}

.presale-section-1 .cont-row .img-cont {
  width: 160px;
}
</style>


<script>
import PublicsaleMixin from '@/mixins/publicsale.mixin.vue'
import ProjectConstants from '@/config/constants.js'
import PrjConst from '@/mixins/prjconst.js'
import UiScroll from '@/mixins/ui-scroll'
import ethUtil from '@/utils/eth-utils'
import moment from 'moment';

let publicSaleStartDate = ProjectConstants.schedulePresaleStart;
let publicSaleEndDate = ProjectConstants.schedulePresaleEnd;
let dueDate = publicSaleStartDate;
if (Date.now() > publicSaleStartDate.getTime()) {
  dueDate = publicSaleEndDate;
}
const zeroPad = (num, places) => String(num).padStart(places, '0');

export default {
  mixins: [ PrjConst, PublicsaleMixin, UiScroll ],
  setup(props, context) {},
  computed: {
    currentRoute: {
      get() {
        return this.$route.name;
      },
    },
  },
  data() {
    return {
      countDays: '00',
      countHours: '00',
      countMins: '00',
      countSecs: '00',
      timer: null,
      publicsaleLive: false,
    }
  },
  unmounted() {
    let vm = this;
    if (this.timer) {
      clearInterval(vm.timer);
      vm.timer = null;
    }
  },
  async mounted() {
    if (Date.now() > publicSaleStartDate.getTime()) {
      this.publicsaleLive = true;
    }
    else {
      this.publicsaleLive = false;
    }
  },
  methods: {
    runCountdown() {
      let vm = this;

      if (vm.endOfSale) {
        vm.countDays = '00';
        vm.countHours = '00';
        vm.countMins = '00';
        vm.countSecs = '00';
        vm.timer = null;
        return;
      }

      let n = moment();
      let dd = moment(dueDate.getTime());

      let diffDays = dd.diff(n, 'days');
      vm.countDays = zeroPad(diffDays, 2);

      let dur = moment.duration(dd.diff(n));
      vm.countHours = zeroPad(dur.get('hours'), 2);
      vm.countMins = zeroPad(dur.get('minutes'), 2);
      vm.countSecs = zeroPad(dur.get('seconds'), 2);

      vm.timer = setTimeout(() => {
        vm.runCountdown()
      }, 1000);
    }

  },
};
</script>
