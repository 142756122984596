
<script>
import { storeComputed, storeMethods } from "@/store/store-helper";
import { walletComputed, walletMethods } from "@/store/wallet-helper";
import { marketComputed, marketMethods } from "@/store/market-helper";
// Smart contract implementations
// ----------------------------------------
import { BigNumber } from "@ethersproject/bignumber";
import { ethers } from "ethers";
let { utils } = ethers;
import { contractAddress, erc20Artifact, publicSaleArtifact } from "@/contracts";
import ethUtil from '@/utils/eth-utils'
import { toPlain } from "@/utils/object-util.js";
import Swal from 'sweetalert2';

import ProjectConstants from '@/config/constants.js'
const log = require('debug')('app:public-sale');
let presaleEndDate = ProjectConstants.schedulePresaleEnd;

// ----------------------------------------
const Big = require('big.js');

function toBigDec(n) {
  let s = new Big(n).toFixed();
  return ethers.utils.parseEther(s);
}

export default {
  setup() {
  },
  computed: {
    ...storeComputed,
    ...walletComputed,
    ...marketComputed,

    endOfSale() {
      if (Date.now() > presaleEndDate.getTime()) return true;
      return false;
    }
  },
  watch: {
    walletAddress() {
      if (this.ethereumDetected && this.mounted) {
        log('+++++++++++ watch updateTokenBalance() at %s', this.walletAddress)
        this.updateTokenBalance();
      }
    },
    walletChainId() {
      if (this.ethereumDetected && this.mounted) {
        this.initPublicSale();
      }
    },
    tetherAmount() {
      if (this.focusAt == "coin") {
        let amt = this.tetherAmount / this.tokenPrice;
        this.tokenAmount = Math.floor(amt * 100) / 100;
      }
    },
    tokenAmount() {
      if (this.focusAt == "token") {
        this.tetherAmount = this.tokenAmount * this.tokenPrice;
      }
    },
  },
  unmounted() {
    this.setSpin(false);
  },
  data() {
    return {
      mounted: false,

      stakeTokenBalance: 0,
      tetherBalance: 0,
      stakeToken: null,

      refAddress: null,

      tetherAmount: 0,
      tokenAmount: 0,
      purchasedAmount: 0,
      focusAt: "",

      permitted: false,

      // PublicSale information
      openTime: 0,
      products: [],
      productId: '',

      // PublicSale info : BigNumber (wei)
      nMinAmount: BigNumber.from(0),

      nMaxCoinVolume: BigNumber.from(0),  // calculated
      maxTetherAmount: '',       // calculated
      maxTokenAmount: '',
      underSale: false,
      tokenPrice: "",
      soldProgress: 0,

      // My Summary of stat
      mySummary: {
          count: 0,
          totalTokenAmount: 0,
          totalTetherAmount: 0,
          claimedAmount: 0,
          claimable: 0
      },
    };
  },
  async mounted() {
    let vm = this;

    let refId = sessionStorage.getItem('ref');
    if (refId) {
      vm.refAddress = refId;
      log('RefAddress : %s', refId)
    }

    if (!vm.ethereumDetected) {
      // if the provider is not detected, detectEthereumProvider resolves to null
      // console.error("Please install MetaMask!", this.ethereumDetected);
    } else {
      await vm.initPublicSale();
      vm.mounted = true;
    }
  },
  methods: {
    ...storeMethods,
    ...walletMethods,
    ...marketMethods,

    async initPublicSale() {
      let vm = this;

      vm.setSpin(true);
      let { contractNames } = ProjectConstants;

      try {
        await vm.updateSaleInformation();

        // inject token address to wallet
        await vm.addWalletToken(contractAddress[contractNames.stakeToken]);
        await vm.addWalletToken(contractAddress[contractNames.tetherToken]);
        await vm.updateTokenBalance();

        // this.runCountdown();
        vm.selectProduct('1');
      }
      catch (e) {
        log('Mounted error : %O', e);
      }

      vm.setSpin(false);
    },
    selectProduct(pid) {
      const vm = this;

      vm.products.forEach(p => {
        if (p.id == pid) {
          vm.tokenPrice = p.tokenPrice;
          vm.productId = pid;
          vm.maxTetherAmount = ethUtil.formatNumber(Math.floor((p.tokenStock - p.soldTokenVolume) * p.tokenPrice));
          vm.maxTokenAmount = ethUtil.formatNumber(Math.floor((p.tokenStock - p.soldTokenVolume)));
        }
      })
    },
    getProduct(pid) {
      const vm = this;
      vm.products.forEach(p => {
        if (p.id == pid) return p;
      })
    },
    async addToken(symbol) {
      const addr = contractAddress[symbol];
      if (addr) {
        ethUtil.addToken(addr);
      }
    },
    toShortAddr(addr) {
      return ethUtil.shortAddr(addr);
    },
    getContracts(bConnect) {
      let vm = this;
      let walletProvider = null;
      
      if (['ethereum', 'ropsten', 'kovan', 'rinkeby', 'goerli'].indexOf(ProjectConstants.network) >= 0) {
        walletProvider = new ethers.providers.Web3Provider(window.ethereum, ProjectConstants.network);
      }

      if (walletProvider == null || vm.walletChainId != ProjectConstants.chainId) {
        walletProvider = new ethers.providers.JsonRpcProvider( ProjectConstants.rpcUrl )
      }

      let { contractNames } = ProjectConstants;

      let stakeToken = new ethers.Contract(
        contractAddress[contractNames.stakeToken],
        erc20Artifact.abi,
        walletProvider
      );

      let tetherToken = new ethers.Contract(
        contractAddress[contractNames.tetherToken],
        erc20Artifact.abi,
        walletProvider
      )

      let publicSale = null;
      if (contractAddress[contractNames.publicSale]) {
        publicSale = new ethers.Contract(
          contractAddress[contractNames.publicSale],
          publicSaleArtifact.abi,
          walletProvider
        );
      }

      if (bConnect && vm.walletAddress) {
        stakeToken = stakeToken.connect(vm.walletAddress);
        if (publicSale) publicSale = publicSale.connect(vm.walletAddress);
      }

      vm.stakeToken = stakeToken;
      vm.tetherToken = tetherToken;

      return {
        walletProvider,
        stakeToken,
        tetherToken,
        publicSale,
      };
    },
    async updateSaleInformation() {
      let vm = this;

      let { publicSale } = vm.getContracts();
      let saleInfo = await publicSale.querySaleInfo();
      log('Sale Info : %O', toPlain(saleInfo));

      vm.openTime = saleInfo.openTime.toNumber();

      // BigNumber (wei)
      vm.nMinAmount = saleInfo.nMinAmount;

      // get current sale prices, volume
      vm.underSale = saleInfo.underSale;

      let saleProducts = await publicSale.getProducts();
      vm.products = formatProducts(saleProducts);

      log('Product info : %O', vm.products);
    },
    ethValue(bn, digits=2) {
      return ethUtil.formatEther(bn, digits);
    },
    localeValue(s, digits=2) {
      return ethUtil.formatNumber(s, digits);
    },
    setTetherAmount(r) {
      let vm = this;

      log('Balance : BNB = %s, TETHER = %s , Ratio : %s', vm.walletBalance, vm.tetherBalance, r);

      if (vm.tetherBalance > 0 && r > 0 && r <= 100) {
        vm.tetherAmount = vm.tetherBalance * r / 100;
        vm.adjustTetherAmount();
      }
    },
    adjustTetherAmount() {
      let vm = this;

      if (vm.tetherBalance > 0) {
        if (Number(vm.tetherAmount) > (vm.tetherBalance)) {
          // balance minus GAS Amount
          vm.tetherAmount = vm.tetherBalance;
        }

        if (Number(vm.tetherAmount) > vm.maxTetherAmount) {
          log('Tether Amount : %s  Max Tether Amount : %s', vm.tetherAmount, vm.maxTetherAmount);
          vm.tetherAmount = vm.maxTetherAmount;
        }
        let amt = vm.tetherAmount / vm.tokenPrice;
        // Decimal point to 2
        vm.tokenAmount = Math.floor(amt * 100) / 100;
      }
    },
    async permitUsdt(isContinueBuy) {
      let vm = this;

      vm.setSpin(true);
      try {
        let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        let { tetherToken, publicSale } = vm.getContracts(true);
        let amount = ethers.utils.parseEther(`${vm.tetherAmount}`);
        let allowance = await tetherToken.allowance(vm.walletAddress, publicSale.address);

        if (allowance.gte(amount)) {
          // OK
        }
        else {
          // permit eternally
          let permitAmount = ethers.constants.MaxUint256;

          if (allowance && allowance.gt(0)) {
            permitAmount = permitAmount.sub(allowance);
          }

          // permit exactly 
          // permitAmount = amount;

          let txApprove = await tetherToken.connect(walletProvider.getSigner()).approve(publicSale.address, permitAmount);
          let receipt = await txApprove.wait();

          let txId = receipt.transactionHash;
          vm.setSpin(false);

          await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Approve USDT',
            html: `<a href="https://bscscan.com/tx/${txId}" target="bscscan.com">View on BscScan </a>`,
            showConfirmButton: true
          });
        }

        vm.permitted = true;
      }
      catch (e) {
        log('Permit error : %O', e)
      }
      vm.setSpin(false);
    },
    async buyToken() {
      let vm = this;
      let ref = vm.refAddress || vm.walletAddress;
      let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      let { tetherToken, publicSale } = vm.getContracts(true);
 
      vm.setSpin(true);
      try {
        let amount = ethers.utils.parseEther(`${vm.tetherAmount}`);

        await vm.permitUsdt(true);
        vm.setSpin(true);

        let tx = await publicSale
          .connect(walletProvider.getSigner())
          .buy(vm.productId, amount);

        let res = await tx.wait();
        let txId = res.transactionHash;
        vm.setSpin(false);

        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Token purchased',
          html: `<a href="https://bscscan.com/tx/${txId}" target="bscscan.com">View on BscScan </a>`,
          showConfirmButton: true
        });

        log('buyToken() ----- request : ref=%s , amount:%s',  ref,  String(amount));

        vm.setSpin(true);
        await vm.updateTokenBalance();
        await vm.updateSaleInformation();
      }
      catch (e) {
        log('Buy token Failed : %O', e);
      }
      vm.tetherAmount = 0;
      vm.tokenAmount = 0;
      vm.setSpin(false);

    },
    async updateTokenBalance() {
      let vm = this;
      vm.setSpin(true);

      try {
        let { tetherToken, publicSale } = vm.getContracts(true);
        let myAddr = this.walletAddress;

        if (myAddr) {
          await vm.updateWalletTokenBalance();
          vm.stakeTokenBalance = vm.getTokenBalance(ProjectConstants.tokenName)
          vm.tetherBalance = vm.getTokenBalance(ProjectConstants.tetherTokenName)

          // Account PreSale Statistics
          let purchased = await publicSale.queryTokenAmount();
          vm.purchasedAmount = utils.formatEther(purchased);

          let summary = await publicSale.querySummary();
          vm.mySummary = vm.formatSummary(summary);
          // log(`-- My Summary : address = ${vm.walletAddress}\n%O`, vm.mySummary);

          let allowance = await tetherToken.allowance(vm.walletAddress, publicSale.address);
          if (allowance && allowance.gte(ethers.utils.parseEther(`${vm.tetherAmount}`))) {
            vm.permitted = true;
          }
        }
        else {
          vm.stakeTokenBalance = 0
          vm.purchasedAmount = 0
          vm.mySummary = {}
        }

        // vm.updateWalletBalance();
      } catch (e) {
        console.error(e);
      }

      vm.setSpin(false);
    },
    formatSummary(s) {
      return {
        count: s.count.toNumber(),
        totalTokenAmount: utils.formatEther(s.totalTokenAmount),
        totalTetherAmount: utils.formatEther(s.totalTetherAmount),
        tokenClaimed: utils.formatEther(s.claimedAmount),
        tokenClaimable: utils.formatEther(s.claimable),
      }
    },

    showReceipt() {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
    },
  },
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
	const utc1 = a.getTime();
	const utc2 = b.getTime();
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function formatProducts(prods) {
  let ret = [];

  prods.forEach(e => {
      let maxTokenCanBuy = utils.formatEther(e.tokenStock.sub(e.soldTokenVolume));
      let maxTetherCanPay = utils.formatEther(e.tokenStock.sub(e.soldTokenVolume).mul(e.tokenPrice));

      ret.push({
        id: Number(e.id),
        minted: e.minted,
        status: e.status,   // 0 = DISPLAY
        claimPeriod: Number(e.claimPeriod), // in seconds
        name: e.name,
        tokenPrice: utils.formatEther(e.tokenPrice),
        tokenStock: utils.formatEther(e.tokenStock),
        saleCount: Number(e.saleCount),
        soldTetherVolume: utils.formatEther(e.soldTetherVolume),
        soldTokenVolume: utils.formatEther(e.soldTokenVolume),
        claimedTokenVolume: utils.formatEther(e.claimedTokenVolume),

        // calculate
        maxTetherAmount: maxTetherCanPay,
        maxTokenAmount: maxTokenCanBuy,
    });
  });

  return ret;
}
</script>